<template>
  <div class="box mb-4">
    <div>
      <apexchart
        width="100%"
        height="300"
        type="line"
        name="chart"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      isLoading: false,
      options: {
        chart: {
          id: "vuechart-example",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          type: "datetime",
          categories: [],
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: undefined,
          width: 2,
          dashArray: 0,
        },
      },
      series: [],
    };
  },
  computed: {
    activeEmployer() {
      return this.$store.getters["employers/getEmployer"];
    },
  },
  methods: {
    async getStats() {
      this.isLoading = true;
      const response = await axios.get(
        "/api/chartstats/" + this.activeEmployer.id + "/"
      );
      // update chart data
      response.data.days.forEach((day) => {
        this.options.xaxis.categories.push(day);
      });
      this.series.push({
        name: "Job impressions",
        data: response.data.job_impressions,
      });
      this.series.push({
        name: "Job opens",
        data: response.data.job_views,
      });
      this.series.push({
        name: "Profile views",
        data: response.data.profile_views,
      });
      this.isLoading = false;
    },
    async updateStats() {
      this.isLoading = true;
      const response = await axios.get(
        "/api/chartstats/" + this.activeEmployer.id + "/"
      );
      // update chart data
      this.options.xaxis.categories = [];
      response.data.days.forEach((day) => {
        this.options.xaxis.categories.push(day);
      });

      this.series = [];
      this.series.push({
        name: "Job impressions",
        data: response.data.job_impressions,
      });
      this.series.push({
        name: "Job opens",
        data: response.data.job_views,
      });
      this.series.push({
        name: "Profile views",
        data: response.data.profile_views,
      });
      this.isLoading = false;
    },
    statsFormat(num) {
      if (num > 999999) {
        return (num / 1000000).toFixed(1) + "M";
      } else if (num > 999) {
        return (num / 1000).toFixed(1) + "K";
      } else {
        // format number including thousand seperators
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  mounted() {
    this.getStats();

    // update chart data every 30 minutes
    setInterval(() => {
      this.updateStats();
    }, 30 * 60 * 1000);
  },
};
</script>

<style></style>
