<template>
  <section>
    <!-- <b-tooltip label="Refresh Employer">
      <button class="button is-light mb-2 mr-2" :loading="isLoading">
        <b-icon icon="refresh" size="is-small"> </b-icon>
      </button>
    </b-tooltip> -->
    <b-tooltip label="Edit Profile">
      <button class="button is-light mb-2" @click="editEmployer">
        <b-icon icon="pencil" size="is-small"> </b-icon>
      </button>
    </b-tooltip>
  </section>
</template>

<script>
export default {
  methods: {
    editEmployer() {
      this.$router.push({
        name: "EmployerProfileEdit",
      });
      window.scrollTo(0, 0);
    },
  },
};
</script>
