<template>
  <section>
    <b-tag type="is-white" size="is-medium"
      ><a @click.prevent="upgradePlan" class="is-underlined"
        >Upgrade plan <i class="ml-2 fa-solid fa-arrow-right-long"></i></a
    ></b-tag>
  </section>
</template>

<script>
export default {
  methods: {
    upgradePlan() {
      this.$router.push({
        name: "EmployerPlans",
      });
      window.scrollTo(0, 0);
    },
  },
};
</script>
