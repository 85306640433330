<template>
  <b-tag
    :type="
      subscription && !subscription.is_expired ? 'is-primary' : 'is-warning'
    "
    class="mr-2 mb-2"
    size="is-medium"
  >
    <b-icon
      class="mr-1"
      :icon="
        subscription && !subscription.is_expired
          ? 'check'
          : 'alert-circle-outline'
      "
      size="is-small"
    >
    </b-icon>
    {{
      subscription && !subscription.is_expired
        ? subscription.plan.description
        : subscription && subscription.is_expired
        ? subscription.plan.description + " (expired)"
        : "No subscription"
    }}
  </b-tag>
</template>

<script>
export default {
  props: {
    subscription: {
      type: Object,
      required: false,
    },
  },
  method: {
    //
  },
};
</script>
