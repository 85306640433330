<template>
  <div class="box">
    <div class="columns mt-2 mb-2">
      <div class="column is-2">
        <figure class="image is-128x128" style="margin: 0 auto">
          <img
            class="img-profile"
            :src="getEmployer.logo ? getEmployer.logo : ''"
          />
        </figure>
      </div>
      <div class="column">
        <div class="flex" style="display: flex; justify-content: space-between">
          <h1
            class="cover-title mb-1"
            style="font-size: 1.5rem; font-weight: 600"
          >
            {{ getEmployer.name }}
          </h1>
          <profile-edit></profile-edit>
        </div>
        <EmployerLink
          :url="getEmployer.get_absolute_url"
          :slug="getEmployer.slug"
        />
        <section class="mt-3">
          <!-- Tags -->
          <SubscriptionTag :subscription="getEmployer.current_subscription" />
          <VerifiedTag :isVerified="getEmployer.is_verified" />
          <ProfilereadyTag :ready="getEmployer.profile_ready" />
        </section>
        <!-- Button to upgrade plan -->
        <UpgradeBtn />
      </div>
    </div>
  </div>
</template>

<script>
import UpgradeBtn from "../small/UpgradeBtn.vue";
import ProfileEdit from "../small/ProfileEditBtn.vue";
import SubscriptionTag from "../small/SubscriptionTag.vue";
import VerifiedTag from "../small/VerifiedTag.vue";
import ProfilereadyTag from "../small/ProfilereadyTag.vue";
import EmployerLink from "../small/EmployerPageLink.vue";

export default {
  components: {
    UpgradeBtn,
    ProfileEdit,
    SubscriptionTag,
    VerifiedTag,
    ProfilereadyTag,
    EmployerLink,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    getEmployer() {
      return this.$store.getters["employers/getEmployer"];
    },
  },
  methods: {
    //
  },
};
</script>

<style scoped>
.grey-text {
  color: #4a4a4a;
}

.grey-text:hover {
  color: #485fc7;
}
</style>
