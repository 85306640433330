<template>
  <b-tag
    :type="ready ? 'is-primary' : 'is-warning'"
    size="is-medium"
    class="mr-2 mb-2"
  >
    <b-icon
      class="mr-1"
      :icon="ready ? 'check' : 'alert-circle-outline'"
      size="is-small"
    >
    </b-icon>
    {{ ready ? "Profile ready" : "Profile incomplete" }}
  </b-tag>
</template>

<script>
export default {
  props: {
    ready: {
      type: Boolean,
      required: false,
    },
  },
  method: {
    //
  },
};
</script>
