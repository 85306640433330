<template>
  <div class="employer-link">
    <p class="article-sub-heading mb-2">
      <a
        :href="'https://www.iulaanu.com' + url"
        target="_blank"
        rel="noopener noreferrer"
        class="grey-text"
      >
        employers/<strong>{{ slug }}</strong>
        <i class="ml-2 fa-solid fa-arrow-up-right-from-square"></i>
      </a>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    slug: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
};
</script>
