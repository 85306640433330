<template>
  <div class="box hero is-light" v-show="!isLoading">
    <nav class="level mt-2 mb-2">
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Active Jobs</p>
          <p class="title">{{ statsFormat(this.active_jobs) }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Job Impressions (30 days)</p>
          <p class="title">{{ statsFormat(total_jobs) }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Job Opens (30 days)</p>
          <p class="title">{{ statsFormat(job_views) }}</p>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <p class="heading">Profile Visits (30 Days)</p>
          <p class="title">{{ statsFormat(profile_views) }}</p>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      isLoading: false,
      active_jobs: 0,
      total_jobs: 0,
      job_impressions: 0,
      profile_views: 0,
      job_views: 0,
    };
  },
  computed: {
    activeEmployer() {
      return this.$store.getters["employers/getEmployer"];
    },
  },
  methods: {
    async getStats() {
      this.isLoading = true;
      const response = await axios.get(
        "/api/employerstats/" + this.activeEmployer.id + "/"
      );
      this.active_jobs = response.data.active_jobs;
      this.total_jobs = response.data.all_jobs;
      this.job_impressions = response.data.job_impressions;
      this.profile_views = response.data.profile_views;
      this.job_views = response.data.job_views;
      this.isLoading = false;
    },
    statsFormat(num) {
      if (num > 999999) {
        return (num / 1000000).toFixed(1) + "M";
      } else if (num > 999) {
        return (num / 1000).toFixed(1) + "K";
      } else {
        // format number including thousand seperators
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  mounted() {
    this.getStats();

    //update stats every 30 mins
    setInterval(() => {
      this.getStats();
    }, 30 * 60 * 1000);
  },
};
</script>

<style></style>
