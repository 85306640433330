<template>
  <b-tag
    :type="isVerified ? 'is-primary' : 'is-warning'"
    class="mr-2 mb-2"
    size="is-medium"
  >
    <b-icon
      class="mr-2"
      :icon="isVerified ? 'check' : 'alert-circle-outline'"
      size="is-small"
    >
    </b-icon>
    {{ isVerified ? "Verified" : "Pending verification" }}</b-tag
  >
</template>

<script>
export default {
  props: {
    isVerified: {
      type: Boolean,
      required: false,
    },
  },
  method: {
    //
  },
};
</script>
