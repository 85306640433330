<template>
  <div class="dashboard" :key="activeEmployer.id">
    <employer-card></employer-card>
    <dashboard-stats></dashboard-stats>
    <dashboard-chart></dashboard-chart>
  </div>
</template>

<script>
import EmployerCard from "../../components/ui/EmployerCard.vue";
import DashboardStats from "../../components/ui/DashboardStats.vue";
import DashboardChart from "../../components/ui/DashboardChart.vue";

export default {
  components: {
    EmployerCard,
    DashboardStats,
    DashboardChart,
  },
  data() {
    return {};
  },
  computed: {
    activeEmployer() {
      return this.$store.getters["employers/getEmployer"];
    },
  },
  methods: {
    refreshEmployer(id) {
      this.$store.dispatch("employers/updateEmployer", id);
    },
  },
  created() {
    this.refreshEmployer(this.activeEmployer.id);
  },
};
</script>
